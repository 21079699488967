import React from "react";
import { ThemeProvider, Button } from "@mui/material";

import theme from "../theme";
import "../App.css";
import "./styles/Home.css";
import Footer from "../Components/Footer";
import WeddingMenu from "../Components/WeddingMenu";
import WeddingHead2 from "../Components/WeddingHead2";

export default function Home() {
  // Corrected buttonStyle object
  const buttonStyle = {
    width: "150px", // Make sure to add units (px)
    color: "white",
    fontSize: "18px",
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontStyle: "normal",
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="our-story">
        <div className="width-limit">
          <WeddingMenu />
          <div className="wedding-head">
            <WeddingHead2 />
          </div>
        </div>
        <img
          src="/images/bench.jpg"
          alt="Charles & Kaitlin"
          className="header-img"
        />
        <p className="text">Please join us for our wedding celebration on</p>
        <strong>MAY 16 2025</strong>
        <div className="rsvp-button-div">
          {/* Apply the buttonStyle object */}
          <Button
            className="rsvp-button"
            variant="contained"
            color="secondary"
            style={buttonStyle} // Use style prop to apply the inline styles
          >
            RSVP
          </Button>
        </div>
        <hr className="middle-hr" />
        <span className="text">
          <p className="ceremony">WEDDING CEREMONY</p>
          <p className="inner-text date-time-text">Friday, May 16, 2025</p>
          <p className="inner-text date-time-text">1:00 PM–2:00 PM</p>
          <br />
          <p className="inner-text">Kingdom Hall of Jehovah's Witnesses</p>
          <p className="inner-text">
            9946 Talbotville Gore Road, St. Thomas, ON, N5P 3T2, Canada
          </p>
          <p className="inner-text">Attire: Formal</p>
        </span>
        <p className="text inner-text">
          Please wear the colours of Spring! (optional)
        </p>

        <Footer />
      </div>
    </ThemeProvider>
  );
}
