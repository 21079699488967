import React, { useState } from "react";
import { ThemeProvider } from "@mui/material";

import theme from "../theme";
import "../App.css";
import "./styles/Photos.css";
import Footer from "../Components/Footer";
import WeddingMenu from "../Components/WeddingMenu";
import WeddingHead2 from "../Components/WeddingHead2";
import ImageWithLoader from "../Components/ImageWithLoader";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Photos() {
  // Array of image paths up to IMG_6366
  const images = [
    "/images/Gallery/IMG_6345.png",
    "/images/Gallery/IMG_6346.png",
    "/images/Gallery/IMG_6347.png",
    "/images/Gallery/IMG_6348.png",
    "/images/Gallery/IMG_6349.png",
    "/images/Gallery/IMG_6351.png",
    "/images/Gallery/IMG_6352.png",
    "/images/Gallery/IMG_6353.png",
    "/images/Gallery/IMG_6354.png",
    "/images/Gallery/IMG_6355.png",
    "/images/Gallery/IMG_6356.png",
    "/images/Gallery/IMG_6357.png",
    "/images/Gallery/IMG_6358.png",
    "/images/Gallery/IMG_6359.png",
    "/images/Gallery/IMG_6360.png",
    "/images/Gallery/IMG_6361.png",
    "/images/Gallery/IMG_6362.png",
    "/images/Gallery/IMG_6363.png",
    "/images/Gallery/IMG_6364.png",
    "/images/Gallery/IMG_6365.png",
    "/images/Gallery/IMG_6366.png",
  ];

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="our-story">
        <div className="width-limit">
          <WeddingMenu />
          <div className="wedding-head">
            <WeddingHead2 />
          </div>
        </div>
        <strong>Swipe through our favorite photos together</strong>
        <Slider className="slider" {...settings}>
          {images.map((image, index) => (
            <div key={index} className="image-container">
              <ImageWithLoader src={image} alt={`Gallery image ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
      <Footer />
    </ThemeProvider>
  );
}
