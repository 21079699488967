import React, { useState } from "react";
import {
  AppBar,
  ThemeProvider,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import "../App.css";
import WeddingMenu from "../Components/WeddingMenu";
import WeddingHead2 from "../Components/WeddingHead2";
import "./styles/OurStory.css";
import theme from "../theme";
import Footer from "../Components/Footer";

function OurStory() {
  return (
    <ThemeProvider theme={theme}>
      <div className="our-story">
        <div className="width-limit">
          <WeddingMenu />
          <div className="wedding-head">
            <WeddingHead2 />
          </div>
          <div className="image-container">
            <div></div>
            <p className="forever-text">From Childhood Friends to Forever</p>
            <img
              src="/images/ourstory.webp"
              alt="Our Story"
              className="our-story-img"
            />
          </div>
          <div className="our-story-text">
            <p>
              Our story is a tale of two young servants of Jehovah navigating
              life. Sometimes stumbling, often soaring, but we have found that
              it always came back to us finding each other.
            </p>
            <br />
            <p>
              We shared a childhood marked with innocence and lots of mutual
              teasing, but as teenagers we drifted apart, pulled by the currents
              of change, moving from the big city to a small town. In the
              unpredictable dance of life, however, our paths began to cross
              again.
            </p>
            <br />
            <p>
              Unbeknownst to Charles, before their paths reconnected, Kaitlin
              had given a silent request to Jehovah. She asked that, if he felt
              that she should deserve a good partner, to make the encounter very
              obvious, as she can be quite oblivious. She asked for a partner
              that would impel her spiritually to go beyond her comfort zone and
              to be the best friend she always wanted.
            </p>
            <br />
            <p>
              Unbeknownst to Kaitlin, Charles said a very similar prayer at that
              same time. He asked Jehovah to provide a partner who could match
              or complement him seamlessly, and who could be a source of
              strength during times of weakness, a propellor to keep him moving
              forward and doing well spiritually.
            </p>
            <br />
            <p>
              Sparks flew as if no time had passed, and Charles asked Kaitlin
              out (obviously). As time went by, both of them came to the
              conclusion that, once again, Jehovah had provided. Charles and
              Kaitlin then decided to make it official, in a promise to stand by
              each other's side with Jehovah's backing, come what may.
            </p>
          </div>
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
}

export { OurStory };
