import React from "react";
import "./styles/Footer.css"; // Import the CSS file for styling

const Footer = () => {
  return (
    <footer>
      <hr />
      <h2>C&K</h2>
    </footer>
  );
};

export default Footer;
