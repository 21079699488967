import theme from "../theme";
import React, { useState } from "react";
import {
  ThemeProvider,
  Toolbar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  AppBar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export default function WeddingMenu() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: "Home", link: "/" },
    { text: "Our Story", link: "/our-story" },
    { text: "Gallery", link: "/gallery" },
    { text: "FAQ", link: "/faq" },
    { text: "Registry", link: "/registry" },
  ];

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem button key={index} component="a" href={item.link}>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="fixed" color="secondary">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Charles & Kaitlin's Wedding
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ width: 130 }} // Custom width in pixels (300px in this case)
        PaperProps={{
          sx: {
            width: 130, // Width of the Drawer
          },
        }}
      >
        {list()}
      </Drawer>
    </ThemeProvider>
  );
}
