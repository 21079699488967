import CountDownTimer from "./CountDownTimer";
import "./styles/WeddingHead2.css"; // Import the CSS file for styling

export default function WeddingHead2() {
  return (
    <div className="head-main-container">
      <div className="head-image-container">
        <img
          src="/images/onthisday.webp"
          alt="Charles and Kaitlin"
          className="head-main-image"
        />
      </div>
      <div className="text-container">
        <p className="main-title">Charles & Kaitlin</p>
        <p className="sub-title">ARE GETTING MARRIED</p>
      </div>
      <CountDownTimer />
    </div>
  );
}
