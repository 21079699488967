import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./App.css";

import Home from "./Sections/Home";
import { OurStory } from "./Sections/OurStory";
import FAQ from "./Sections/FAQ";
import Registry from "./Sections/Registry";
import Photos from "./Sections/Photos.jsx";

export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-story" element={<OurStory />} />
        <Route path="/Gallery" element={<Photos />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/Registry" element={<Registry />} />
      </Routes>
    </BrowserRouter>
  );
}