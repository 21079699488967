import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { SyncLoader } from "react-spinners"; // Spinner component

export default function ImageWithLoader({ src, alt }) {
  const [loading, setLoading] = useState(true);

  return (
    <div className="image-wrapper">
      {loading && (
        <div className="spinner-wrapper">
          <SyncLoader color="#c5a471" size={10} />
        </div>
      )}
      <LazyLoadImage
        src={src}
        alt={alt}
        className="header-img"
        effect="blur"
        afterLoad={() => setLoading(false)} // Stop showing the spinner once the image is loaded
      />
    </div>
  );
}
