import React, { useState } from "react";
import {
  AppBar,
  ThemeProvider,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import "../App.css";
import WeddingMenu from "../Components/WeddingMenu";
import WeddingHead2 from "../Components/WeddingHead2";
import "./styles/Registry.css";
import theme from "../theme";
import Footer from "../Components/Footer";

export default function Registry() {
  return (
    <ThemeProvider theme={theme}>
      <div className="registry">
        <div className="width-limit">
          <WeddingMenu />
          <div className="wedding-head">
            <WeddingHead2 />
            <div className="registry-text">
              <p>
                As we embark on building our life together, we’re excited to
                start creating a home filled with love and memories. Since we
                are just beginning to furnish and set up our new space, any help
                is truly appreciated!
              </p>
              <br />
              <p>
                If you’d like to contribute, we kindly prefer cash (e-transfer
                is easiest) or gift cards (to places like Homesense, Costco,
                IKEA, etc.) as this will help us make our home feel truly ours
                and take care of the essentials we need.
              </p>
              <br />
              <p>
                We know some may enjoy picking out a physical gift, so we’ve
                also created a small Amazon registry with some items that will
                help us along the way.
              </p>
              <br />
              <p>
                Your love and support mean everything to us, and we can’t wait
                to celebrate this special time together!
              </p>
              <br />
              <p>
                If you wish to send an e-transfer, please send it to the
                following email:{"\t"}
                <a
                  href="mailto:Charles.work20@gmail.com"
                  style={{ color: "#c5a471" }}
                >
                  Charles.work20@gmail.com
                </a>
              </p>
            </div>
            <br />
            <a
              href="https://www.amazon.ca/wedding/registry/2OWCXTDLGVVBK"
              target="_blank"
            >
              <img
                src="/images/amazonbtn.png"
                alt="Our Story"
                className="amazonbtn-img"
              />
            </a>
            <div className="registry-text">
              <p>Click the button above to access the registry</p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
}
