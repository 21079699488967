import React, { useState, useEffect } from "react";
import "./styles/CountDownTimer.css"; // Import the CSS file for styling

const CountDownTimer = ({ targetDate = "2025-05-16T13:00:00" }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: "000",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const countDownDate = new Date(targetDate).getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = String(
        Math.floor(distance / (1000 * 60 * 60 * 24))
      ).padStart(3, "0");
      const hours = String(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      ).padStart(2, "0");
      const minutes = String(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      ).padStart(2, "0");
      const seconds = String(
        Math.floor((distance % (1000 * 60)) / 1000)
      ).padStart(2, "0");

      if (distance < 0) {
        clearInterval(interval);
        setTimeRemaining({
          days: "000",
          hours: "00",
          minutes: "00",
          seconds: "00",
        });
      } else {
        setTimeRemaining({
          days,
          hours,
          minutes,
          seconds,
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div className="countdown-timer-container">
      <div className="countdown-item">
        <span className="countdown-number">{timeRemaining.days}</span>
        <span className="countdown-label">Day(s)</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-number">{timeRemaining.hours}</span>
        <span className="countdown-label">Hour(s)</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-number">{timeRemaining.minutes}</span>
        <span className="countdown-label">Minute(s)</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-number">{timeRemaining.seconds}</span>
        <span className="countdown-label">Second(s)</span>
      </div>
    </div>
  );
};

export default CountDownTimer;
