import React from "react";
import { ThemeProvider } from "@mui/material";
import theme from "../theme";
import "../App.css";
import "./styles/FAQ.css";
import WeddingMenu from "../Components/WeddingMenu";
import WeddingHead2 from "../Components/WeddingHead2";
import Footer from "../Components/Footer";

const FAQ = () => {
  const faqData = [
    {
      question: "WHAT DATE SHOULD I RSVP BY?",
      answer:
        "March 22nd of 2025 is the deadline for an RSVP! We will reach out to our guests if the deadline is closely approaching as a gentle reminder.",
    },
    {
      question: "IS THERE PARKING AVAILABLE NEAR YOUR WEDDING VENUES?",
      answer:
        "Yes! We do encourage carpooling where appropriate, but there is suitable parking at both venues for guests when arriving to avoid delays or inconvenience.",
    },
    {
      question: "WILL YOUR WEDDING BE INDOORS OR OUTDOORS?",
      answer:
        "It will be indoor for our ceremony. At the reception venue, there is the option to enjoy the outside lounge and enviroment as you please.",
    },
    {
      question:
        "ARE THERE ANY COLORS OR STYLES YOU WOULD PREFER THE GUESTS TO WEAR?",
      answer:
        'While the dress code is expected to be formal, please feel free to dress according to the season in your favourite "spring" colours to complement the day and atmosphere!',
    },
    {
      question: 'AM I ALLOWED TO BRING A "PLUS ONE?"',
      answer:
        "Unfortunately, due to venue capacities and our desire to keep the wedding intimate, we are reserving seats specifically for the names invited as stated on the envelope.",
    },
    {
      question:
        "WILL THERE BE MEAL OPTIONS FOR GUESTS WITH DIETARY RESTRICTIONS OR ALLERGIES?",
      answer:
        "Yes, our menu caters very well to those with dietary restrictions and allergies, especially if you are vegetarian or gluten free. If there are any additional intolerances or concerns you would like us to be aware of, please reach out to the bride or groom for correspondence.",
    },
    {
      question: "WILL THERE BE AN OPEN BAR?",
      answer:
        "As we have a desire to maintain modesty for our big day, we will not be hosting an open bar. However, we understand the desire to enjoy good food with a hearty drink along with friends, so we are glad to provide two drinks",
    },
    {
      question: "WHERE SHOULD WE BRING/SEND OUR WEDDING GIFT?",
      answer:
        "Your presence at our wedding is the greatest gift we could ask for! However, if you would like to honour us with a gift, please visit the registry page on this site for more details. There will be a card box as well, at the reception.",
    },
    // Add more FAQ items here
  ];

  return (
    <ThemeProvider theme={theme}>
      <div className="body-container">
        <div className="width-limit">
          <WeddingMenu />
          <div className="wedding-head">
            <WeddingHead2 />
          </div>

          <h1>Frequently Asked Questions</h1>
          {faqData.map((item, index) => (
            <div key={index}>
              <h3 className="question">{item.question}</h3>
              <p className="answer">{item.answer}</p>
            </div>
          ))}
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default FAQ;
